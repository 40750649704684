import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import styled from 'styled-components'
import "./layout.css"

const StyledFooter = styled.div`
  margin-top: 9rem;
  overflow: hidden;

  footer {
    padding: .5rem 1rem;
    display: flex;
    max-width: 1800px;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  .heart {
    fill: red;
    position: relative;
    margin: 3rem auto;
    top: 0;
    width: 10px;
    height: 28px;

    animation: pulse 1s ease infinite;
  }

  @keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

`;


class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCredits = this.toggleCredits.bind(this);

    this.state = {
      showCredits: false,
    }
  }

  toggleCredits() {
    let {
      showCredits,
    } = this.state;

    this.setState({
      showCredits: !showCredits,
    });
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      showCredits,
    } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query SiteConfig {
            site {
              siteMetadata {
                title
              }
            }
            wordpressWpApiMenusMenusItems(slug: { eq: "primary"}){
              items {
                title
                object_slug
                url
              }
            }
          }
        `}
        render={data => {
          return (
          <>
            <div>
              <main>{children}</main>
              <StyledFooter>
                <footer onClick={this.toggleCredits}>
                  {showCredits && <p>This site was made using <a href="https://gatsby.com">Gatsby</a> and <a href="https://wordpress.org">WordPress</a></p>}
                  <svg viewBox="0 0 32 29.6" className="heart">
                    <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                    c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"></path>
                  </svg>
                </footer>
              </StyledFooter>
            </div>
          </>
        )}
        }
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showCredits: PropTypes.string,
}

export default Layout
